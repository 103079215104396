<template>

  <ion-page>
    <ion-header>

      <ion-toolbar>
        <div slot="start" class="ion-padding-start">
          <img class="h-6" src="/assets/images/wollo-brand-green.svg" />
        </div>
        <ion-buttons slot="end" class="ion-padding-end">
          <div v-if="exchangeRates.USDCentsPerDeSoExchangeRate" class="flex items-center text-sm">
            <img class="deso-xs" src="/assets/images/deso.svg" /> ≈
            {{ utils.displayFiat(exchangeRates.USDCentsPerDeSoExchangeRate) }} USD
          </div>
        </ion-buttons>

        <div class="flex justify-center">
        </div>
      </ion-toolbar>

    </ion-header>

    <ion-content class="ion-padding">
      <div v-if="loading">
        <ion-list>
          <ion-item lines="none">
            <ion-label>Loading</ion-label>
            <ion-spinner slot="start"></ion-spinner>
          </ion-item>
        </ion-list>
      </div>
      <div v-else>
          <h2 class="">Recent Activity</h2>
          <ul class="no-padding mt-3">
            <li class="mt-6" v-for="item in sortedNotifications" :key="item.id">
              <ion-card class="no-padding" v-if="item.TransactionType == 'ACCEPT_NFT_BID'">
                <ion-card-header>                  
                  <ion-card-subtitle class="flex items-center" v-if="item.Post.account.publicKey != item.TransactorPublicKeyBase58Check">
                    <img class="w-6 rounded-full mr-1" :title="item.Post.account.Username" :src="utils.publicKeyImageUrl(item.Post.account.publicKey)"> BID WAS ACCEPTED</ion-card-subtitle>
                  <ion-card-title class="text-lg">{{ item.Post.body }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                  <div>
                    <ion-img v-if="item.Post.imageUrl" :src="item.Post.imageUrl"/>
                  </div>
                  <div class="mt-1">
                    Accepted by: {{ item.Transactor.Username }}
                  </div>
                </ion-card-content>
              </ion-card>

              <ion-card class="no-padding" v-else-if="item.TransactionType == 'UPDATE_NFT'">
                <ion-card-header>
                  <ion-card-subtitle class="flex items-center">
                    <img class="w-6 rounded-full mr-1" :title="item.Post.account.Username" :src="utils.publicKeyImageUrl(item.Post.account.publicKey)"> UPDATED</ion-card-subtitle>
                  <ion-card-title class="text-lg">{{ item.Post.body }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                  <div>
                    <ion-img v-if="item.Post.imageUrl" :src="item.Post.imageUrl"/>
                  </div>
                </ion-card-content>
              </ion-card>

              <ion-card class="no-padding" v-else-if="item.TransactionType == 'NFT_BID'">
                <ion-card-header>
                  <ion-card-subtitle class="flex items-center">
                    <img class="w-6 rounded-full mr-1" :title="item.Post.account.Username" :src="utils.publicKeyImageUrl(item.Post.account.publicKey)"> BID {{ utils.displayNanos(item.BidAmountNanos) }} DESO on</ion-card-subtitle>
                  <ion-card-title class="text-lg">{{ item.Post.body }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                  <div>
                    <ion-img v-if="item.Post.imageUrl" :src="item.Post.imageUrl"/>
                  </div>
                  <div class="mt-1">
                    Current owner: {{ item.Owner.Username }}
                  </div>
                </ion-card-content>
              </ion-card>
              <ion-card class="no-padding" v-else-if="item.TransactionType == 'ACCEPT_NFT_TRANSFER'">
                <ion-card-header>
                  <ion-card-subtitle class="flex items-center">
                    <img class="w-6 rounded-full mr-1" :title="item.Post.account.Username" :src="utils.publicKeyImageUrl(item.Post.account.publicKey)">
                     ACCEPTED</ion-card-subtitle>
                  <ion-card-title class="text-lg">{{ item.Post.body }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                  <div>
                    <ion-img v-if="item.Post.imageUrl" :src="item.Post.imageUrl"/>
                  </div>
                  <div class="mt-1">
                    Collection: {{ item.Post.collection.username }}
                  </div>
                </ion-card-content>
              </ion-card>       

              <ion-card class="no-padding" v-else-if="item.TransactionType == 'NFT_TRANSFER'">
                <ion-card-header>
                  <ion-card-subtitle class="flex items-center">
                    <img v-if="item.Post && item.Post.Account" class="w-6 rounded-full mr-1" :title="item.Post.account.Username" :src="utils.publicKeyImageUrl(item.Post.account.publicKey)">
                    TRANSFERRED</ion-card-subtitle>
                  <ion-card-title v-if="item.Post" class="text-lg">{{ item.Post.body }}</ion-card-title>
                </ion-card-header>

              </ion-card>       


              <ion-card class="no-padding" v-else-if="item.TransactionType == 'BURN_NFT' && item.Post">
                <ion-card-header>
                  <ion-card-subtitle class="flex items-center">
                    <img class="w-6 rounded-full mr-1" :title="item.Post.account.Username" :src="utils.publicKeyImageUrl(item.Post.account.publicKey)">
                    BURNED</ion-card-subtitle>
                  <ion-card-title class="text-lg">{{ item.Post.body }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                  <div>
                    <ion-img v-if="item.Post.imageUrl" :src="item.Post.imageUrl"/>
                  </div>
                </ion-card-content>
              </ion-card>

              <ion-card class="no-padding" v-else-if="item.TransactionType == 'CREATE_NFT' && item.Post">
                <ion-card-header>
                  <ion-card-subtitle class="flex items-center">
                    <img class="w-6 rounded-full mr-1" :title="item.Post.account.Username" :src="utils.publicKeyImageUrl(item.Post.account.publicKey)">
                    CREATED</ion-card-subtitle>
                  <ion-card-title class="text-lg">{{ item.Post.body }}</ion-card-title>
                </ion-card-header>

                <ion-card-content>
                  <div>
                    <ion-img v-if="item.Post.imageUrl" :src="item.Post.imageUrl"/>
                  </div>
                </ion-card-content>
              </ion-card>

              <ion-card class="no-padding" v-else>
                <ion-card-content>
                  ## {{ item.TransactionType }}
                </ion-card-content>
              </ion-card>

            </li>
          </ul>
      </div>
    </ion-content>
  </ion-page>

</template>

<script>
import { ref, computed, defineComponent } from "vue";
import { Utils } from "../services/utils";
import { useStore } from "vuex";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonToolbar,

  IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,

  IonImg,
  // IonAvatar,

} from '@ionic/vue';

export default defineComponent({
  components: { 
    IonButtons,
    IonContent, 
    IonHeader, 
    IonPage, 
    IonItem,
    IonLabel,
    IonList,
    IonSpinner,
    IonToolbar,

    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,

    IonImg,
    // IonAvatar,
  },

  setup() {
    const utils = new Utils();
    const store = useStore();
    const loading = ref(true);

    store.dispatch("loadAccounts").then(function() {
      store.dispatch("loadNotifications").then(function() {
        loading.value = false;
      });
    }) 

    const exchangeRates = computed(function () {
      return store.getters.exchangeRates;
    });

    const accounts = computed(function () {
      return store.getters.accounts;
    });

    const notifications = computed(function () {
      return store.getters.notifications;
    });

    const sortedNotifications = computed(function () {
      let list = notifications.value;
      list = list.sort((a, b) => b.BlockTstampSecs - a.BlockTstampSecs);
      return list;
    });

    function refresh(event) {
      loading.value = true;
      store.dispatch("loadAccounts").then(function() {
        store.dispatch("loadNotifications").then(function() {
          loading.value = false;
          event.target.complete();
        });
      }) 
    }

    return {
      utils: utils,
      loading: loading,

      accounts: accounts,
      notifications: notifications,
      sortedNotifications: sortedNotifications,

      exchangeRates: exchangeRates,
      refresh: refresh,

    }

  }


});
</script>
